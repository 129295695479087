var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.organisation-add-image",
              modifiers: { "organisation-add-image": true },
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "organisation-add-image",
            "sidebar-class": "small_sidebar",
            right: "",
            lazy: "",
            backdrop: "",
            "no-slide": "",
            shadow: "",
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
            _c("div", { staticClass: "px-3 py-2 create-message" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("ORGANISATION.CHANGE_IMAGE")))]),
              _c(
                "div",
                { staticClass: "pt-2" },
                [
                  _c("center", [
                    _c(
                      "div",
                      { staticClass: "org-logo" },
                      [_c("b-img", { attrs: { src: _vm.organisationLogo } })],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-group row" },
                    [
                      _c("label", { staticClass: "col-form-label" }, [
                        _vm._v(_vm._s(_vm.$t("SELECT_FILE"))),
                      ]),
                      _c("b-form-file", {
                        attrs: {
                          placeholder: _vm.$t("SELECT"),
                          accept: ".jpg, .png, .gif",
                          state: Boolean(_vm.addFormImage),
                        },
                        on: { change: _vm.changedImage },
                        model: {
                          value: _vm.addFormImage,
                          callback: function ($$v) {
                            _vm.addFormImage = $$v
                          },
                          expression: "addFormImage",
                        },
                      }),
                      _c("b-form-text", [
                        _vm._v(_vm._s(_vm.$t("ORGANISATION.IMAGE_SPEC"))),
                      ]),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: {
                        disabled: !Boolean(_vm.addFormImage),
                        variant: "primary",
                      },
                      on: { click: _vm.saveImage },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: {
                        disabled: !_vm.organisation.image_uuid,
                        variant: "danger",
                      },
                      on: { click: _vm.removeImage },
                    },
                    [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }