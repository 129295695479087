<template>
  <span>
    <span v-b-toggle.organisation-add-image>
      <slot></slot>
    </span>
    <b-sidebar
      id="organisation-add-image"
      sidebar-class="small_sidebar"
      v-model="show"
      right
      lazy
      backdrop
      no-slide
      shadow
    >
      <b-overlay :show="loading" rounded="sm">

        <div class="px-3 py-2 create-message">
          <h3>{{ $t("ORGANISATION.CHANGE_IMAGE") }}</h3>

          <div class="pt-2">
            <center>
              <div class="org-logo">
                <b-img :src="organisationLogo"></b-img> 
              </div>

            </center>


            <div class="form-group row">
              <label class="col-form-label">{{ $t("SELECT_FILE") }}</label>
              <b-form-file
                :placeholder="$t('SELECT')"
                @change="changedImage"
                accept=".jpg, .png, .gif"
                v-model="addFormImage"
                :state="Boolean(addFormImage)"
              ></b-form-file>
              <b-form-text>{{ $t('ORGANISATION.IMAGE_SPEC') }}</b-form-text>
            </div>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button
              :disabled="!Boolean(addFormImage)"
              variant="primary"
              class="btn-fill"
              @click="saveImage"
              >{{ $t("SAVE") }}</b-button
            >

            <b-button
              :disabled="!organisation.image_uuid"
              variant="danger"
              class="float-right"
              @click="removeImage"
              >{{ $t("REMOVE") }}</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
  </span>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      loading: false,
      addFormImage: null,
      show: false,
      image_uuid: this.organisation.image_uuid,
    };
  },
  methods: {
    changedImage(e) {
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          this.imageUrl = reader.result;
        }.bind(this),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    },
    removeImage: function() {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
        h(
          "p",
          { class: ["text-center mb-0 pb-0"] },
          this.$t("CONFIRM.DELETE_ORGANISATION_LOGO")
        ),
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function(value) {
          if (value) {
           
            self.loading = true;
            self.$http
              .post(self.user.hostname + "/organisation/image/remove", {
                organisation_id: self.organisation.id
              })
              .then(function() {
                self.loading = false;
                self.$noty.info(self.$t("REMOVED"));
                self.$emit("changedImage", "");
              })
              .catch(function() {
                self.loading = false;
              });



          }
        })
        .catch(function() {});


    },
    saveImage: function() {
      let self = this;
      this.loading = true;
      let formData = new FormData();
      formData.append("Image", this.addFormImage);
      formData.append("organisation_id", this.organisation.id);
      this.$http
        .post(this.user.hostname + "/organisation/image/add", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        .then(function(response) {
          self.loading = false;
          self.$noty.info(self.$t("SAVED"));
          self.image_uuid = response.data.image_uuid;
          self.$emit("changedImage", response.data.image_uuid);
        })
        .catch(function() {
          self.loading = false;
        });
    },
  },
  computed: {
    organisationLogo() {
      if( (this.organisation.image_uuid == null) || (this.organisation.image_uuid == "") ) {
        return "/img/organisation.png" +
          "?uuid=" +
          this.image_uuid;
      } else {
        return this.user.hostname + "/organisation/image/" +
          this.organisation.id +
          "?uuid=" +
          this.image_uuid;
      }
    }
  },
  mounted() {
  },
};
</script>
<style></style>
