<template>
  <section v-if="information">
    <b-alert
      show
      variant="warning"
      v-if="information.invite == 1"
      class="mt-2"
      >{{ $t("ORGANISATION.INVITED") }}</b-alert
    >

    <b-alert
      show
      variant="warning"
      v-if="information.state == 0"
      class="mt-2"
      >{{ $t("ORGANISATION.NOT_ACTIVATED") }}</b-alert
    >

    <h3 class="mt-2 mb-0">
      <b-avatar
        class="profileImage"
        variant="light"
        size="4rem"
        :src="organisationImage"
        badge-variant="light"
      >
        <template #badge>
          <AddImage
            :organisation="information"
            @changedImage="changedImage"
            v-b-tooltip.hover
            :title="$t('ORGANISATION.CHANGE_IMAGE')"
            ><i class="fal fa-pencil-alt"></i
          ></AddImage>
        </template>
      </b-avatar>
      {{ information.name }}
    </h3>

    <b-nav tabs v-if="inAdministration">
      <b-nav-item
        :to="{ name: 'Administration.Organisation.Nodes' }"
        v-if="information.sefos_node"
      >
        {{ $t("NODES") }}</b-nav-item
      >

      <b-nav-item
        v-if="!information.sefos_node"
        :to="{ name: 'Administration.Organisation.Users' }"
      >
        {{ $t("USERS") }}</b-nav-item
      >

      <b-nav-item-dropdown
        id="organisation-information"
        :text="$t('INFORMATION')"
        right
      >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Administration.Organisation.Information' }"
        >
          {{ $t("DETAILS") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Administration.Organisation.Statistics' }"
        >
          {{ $t("STATISTICS.TITLE") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Administration.Organisation.Log' }"
        >
          {{ $t("LOGG") }}</b-dropdown-item
        >
      </b-nav-item-dropdown>

      <b-nav-item-dropdown
        id="organisation-settings"
        :text="$t('MANAGE')"
        right
      >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Administration.Organisation.Settings.FunctionBox' }"
        >
          {{ $t("FUNCTIONBOX.TITLE") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Administration.Organisation.Settings.Api' }"
        >
          {{ $t("API") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Administration.Organisation.Settings.Email' }"
        >
          {{ $t("EMAIL_OUTGOING") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="information.is_admin == 0"
          :to="{ name: 'Administration.Organisation.Settings.Prices' }"
        >
          {{ $t("PRICES") }}</b-dropdown-item
        >
        <b-dropdown-item
          :to="{ name: 'Administration.Organisation.Settings.Loa' }"
        >
          {{ $t("LOA.TITLE") }}</b-dropdown-item
        >
        <b-dropdown-item
          :to="{ name: 'Administration.Organisation.Settings.Manage' }"
        >
          {{ $t("SETTINGS") }}</b-dropdown-item
        >
      </b-nav-item-dropdown>
    </b-nav>

    <b-nav tabs v-if="!inAdministration">
      <b-nav-item
        v-if="information.sefos_node"
        :to="{ name: 'Organisation.Nodes' }"
      >
        {{ $t("NODES") }}</b-nav-item
      >

      <b-nav-item
        v-if="!information.sefos_node"
        :to="{ name: 'Organisation.Users' }"
      >
        {{ $t("USERS") }}</b-nav-item
      >
      <b-nav-item-dropdown
        id="organisation-information"
        :text="$t('INFORMATION')"
        right
      >
        <b-dropdown-item :to="{ name: 'Organisation.Information' }">
          {{ $t("INFORMATION") }}</b-dropdown-item
        >
        <b-dropdown-item :to="{ name: 'Organisation.Statistics' }">
          {{ $t("STATISTICS.TITLE") }}</b-dropdown-item
        >


        <b-dropdown-item :to="{ name: 'Organisation.Log' }">
          {{ $t("LOGG") }}</b-dropdown-item
        >
        
      </b-nav-item-dropdown>

      <b-nav-item-dropdown
        id="organisation-settings"
        :text="$t('MANAGE')"
        right
      >

        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Organisation.Settings.Api' }"
        >
          {{ $t("API") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Organisation.Settings.FunctionBox' }"
        >
          {{ $t("FUNCTIONBOX.TITLE") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="!information.sefos_node"
          :to="{ name: 'Organisation.Settings.Email' }"
        >
          {{ $t("EMAIL_OUTGOING") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="user.information.is_admin == 2"
          :to="{ name: 'Organisation.Settings.Prices' }"
        >
          {{ $t("PRICES") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="user.information.is_admin >= 1"
          :to="{ name: 'Organisation.Settings.Loa' }"
        >
          {{ $t("LOA.TITLE") }}</b-dropdown-item
        >
        <b-dropdown-item
          v-if="user.information.is_admin >= 1"
          :to="{ name: 'Organisation.Settings.Manage' }"
        >
          {{ $t("SETTINGS") }}</b-dropdown-item
        >
      </b-nav-item-dropdown>




    </b-nav>

    <router-view
      :key="keyCount"
      :organisation="information"
      @updatedPrice="updatedPrice"
      @updatedInformation="updatedInformation"
    ></router-view>
  </section>
</template>
<script>
import AddImage from "@/components/Organisation/AddImage";
export default {
  components: { AddImage },
  props: ["organisation_id"],
  data() {
    return {
      keyCount: 0,
      information: null,
      image_uuid: "",
      menu: [],
    };
  },
  methods: {
    updatedInformation: function(updatedInformation) {
      this.information = updatedInformation;
      this.keyCount = this.keyCount + 1;
    },
    updatedPrice: function(updatedPrice) {
      this.information.prices = updatedPrice;
      //console.log(updatedPrice);
      this.keyCount = this.keyCount + 1;
    },
    getInformation: function() {
      let self = this;
      this.$http
        .get(this.user.hostname + "/organisation/information/" + this.organisation_id)
        .then((response) => {
          self.keyCount = self.keyCount + 1;
          self.information = response.data;
          self.image_uuid = response.data.image_uuid;
        })
        .catch(() => {
        });
    },
    async changedImage(image_uuid) {
      this.image_uuid = image_uuid;
      this.information.image_uuid = image_uuid;
      await this.$store.dispatch("user/fetchUser");
    },
  },
  watch: {
    organisation_id: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getInformation();
      }
    },
  },
  computed: {
    organisationImage() {
      if (this.information.image_uuid == null) {
        return "/img/organisation.png";
      } else {
        return (
          this.user.hostname + "/organisation/image/" +
          this.information.id +
          "?" +
          this.image_uuid
        );
      }
    },
    inAdministration() {
      return (
        this.$route.path.includes("administration") &&
        this.user.information.is_admin == 2
      );
    },
    showEditPrice() {
      if (
        this.user.information.is_admin == 2 &&
        this.information.sefos_node == 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getInformation();
  },
};
</script>
<style></style>
