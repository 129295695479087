var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.information
    ? _c(
        "section",
        [
          _vm.information.invite == 1
            ? _c(
                "b-alert",
                {
                  staticClass: "mt-2",
                  attrs: { show: "", variant: "warning" },
                },
                [_vm._v(_vm._s(_vm.$t("ORGANISATION.INVITED")))]
              )
            : _vm._e(),
          _vm.information.state == 0
            ? _c(
                "b-alert",
                {
                  staticClass: "mt-2",
                  attrs: { show: "", variant: "warning" },
                },
                [_vm._v(_vm._s(_vm.$t("ORGANISATION.NOT_ACTIVATED")))]
              )
            : _vm._e(),
          _c(
            "h3",
            { staticClass: "mt-2 mb-0" },
            [
              _c("b-avatar", {
                staticClass: "profileImage",
                attrs: {
                  variant: "light",
                  size: "4rem",
                  src: _vm.organisationImage,
                  "badge-variant": "light",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "badge",
                      fn: function () {
                        return [
                          _c(
                            "AddImage",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true },
                                },
                              ],
                              attrs: {
                                organisation: _vm.information,
                                title: _vm.$t("ORGANISATION.CHANGE_IMAGE"),
                              },
                              on: { changedImage: _vm.changedImage },
                            },
                            [_c("i", { staticClass: "fal fa-pencil-alt" })]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2724763152
                ),
              }),
              _vm._v("\n    " + _vm._s(_vm.information.name) + "\n  "),
            ],
            1
          ),
          _vm.inAdministration
            ? _c(
                "b-nav",
                { attrs: { tabs: "" } },
                [
                  _vm.information.sefos_node
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            to: { name: "Administration.Organisation.Nodes" },
                          },
                        },
                        [_vm._v("\n      " + _vm._s(_vm.$t("NODES")))]
                      )
                    : _vm._e(),
                  !_vm.information.sefos_node
                    ? _c(
                        "b-nav-item",
                        {
                          attrs: {
                            to: { name: "Administration.Organisation.Users" },
                          },
                        },
                        [_vm._v("\n      " + _vm._s(_vm.$t("USERS")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: {
                        id: "organisation-information",
                        text: _vm.$t("INFORMATION"),
                        right: "",
                      },
                    },
                    [
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Administration.Organisation.Information",
                                },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("DETAILS")))]
                          )
                        : _vm._e(),
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Administration.Organisation.Statistics",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("STATISTICS.TITLE"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: { name: "Administration.Organisation.Log" },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("LOGG")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: {
                        id: "organisation-settings",
                        text: _vm.$t("MANAGE"),
                        right: "",
                      },
                    },
                    [
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Administration.Organisation.Settings.FunctionBox",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("FUNCTIONBOX.TITLE"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Administration.Organisation.Settings.Api",
                                },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("API")))]
                          )
                        : _vm._e(),
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Administration.Organisation.Settings.Email",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " + _vm._s(_vm.$t("EMAIL_OUTGOING"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.information.is_admin == 0
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Administration.Organisation.Settings.Prices",
                                },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("PRICES")))]
                          )
                        : _vm._e(),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: {
                              name: "Administration.Organisation.Settings.Loa",
                            },
                          },
                        },
                        [_vm._v("\n        " + _vm._s(_vm.$t("LOA.TITLE")))]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: {
                              name: "Administration.Organisation.Settings.Manage",
                            },
                          },
                        },
                        [_vm._v("\n        " + _vm._s(_vm.$t("SETTINGS")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.inAdministration
            ? _c(
                "b-nav",
                { attrs: { tabs: "" } },
                [
                  _vm.information.sefos_node
                    ? _c(
                        "b-nav-item",
                        { attrs: { to: { name: "Organisation.Nodes" } } },
                        [_vm._v("\n      " + _vm._s(_vm.$t("NODES")))]
                      )
                    : _vm._e(),
                  !_vm.information.sefos_node
                    ? _c(
                        "b-nav-item",
                        { attrs: { to: { name: "Organisation.Users" } } },
                        [_vm._v("\n      " + _vm._s(_vm.$t("USERS")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: {
                        id: "organisation-information",
                        text: _vm.$t("INFORMATION"),
                        right: "",
                      },
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: { name: "Organisation.Information" } } },
                        [_vm._v("\n        " + _vm._s(_vm.$t("INFORMATION")))]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: { name: "Organisation.Statistics" } } },
                        [
                          _vm._v(
                            "\n        " + _vm._s(_vm.$t("STATISTICS.TITLE"))
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: { name: "Organisation.Log" } } },
                        [_vm._v("\n        " + _vm._s(_vm.$t("LOGG")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      attrs: {
                        id: "organisation-settings",
                        text: _vm.$t("MANAGE"),
                        right: "",
                      },
                    },
                    [
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: { name: "Organisation.Settings.Api" },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("API")))]
                          )
                        : _vm._e(),
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "Organisation.Settings.FunctionBox",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(_vm.$t("FUNCTIONBOX.TITLE"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.information.sefos_node
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: { name: "Organisation.Settings.Email" },
                              },
                            },
                            [
                              _vm._v(
                                "\n        " + _vm._s(_vm.$t("EMAIL_OUTGOING"))
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.user.information.is_admin == 2
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: { name: "Organisation.Settings.Prices" },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("PRICES")))]
                          )
                        : _vm._e(),
                      _vm.user.information.is_admin >= 1
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: { name: "Organisation.Settings.Loa" },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("LOA.TITLE")))]
                          )
                        : _vm._e(),
                      _vm.user.information.is_admin >= 1
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: { name: "Organisation.Settings.Manage" },
                              },
                            },
                            [_vm._v("\n        " + _vm._s(_vm.$t("SETTINGS")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("router-view", {
            key: _vm.keyCount,
            attrs: { organisation: _vm.information },
            on: {
              updatedPrice: _vm.updatedPrice,
              updatedInformation: _vm.updatedInformation,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }